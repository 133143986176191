<template>
    <div class="main-page">
        <second-navbar />
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-4 d-flex flex-column align-items-center justify-content-center vh-100">
                    <div class="bd-highlight pb-5">
                        <img src="@/assets/icon/check.png" alt="">
                    </div>
                    <div class="bd-highlight pt-2 pb-1">
                        <p class="text-gray-1 fm-poppins fst-normal fw-600 fs-36 lh-36">Yeeeey</p>
                    </div>
                    <div class="bd-highlight pb-3">
                        <p class="text-gray-3 fm-nunito fst-normal fw-normal fs-14 lh-14 text-center">Pembayaran Berhasil</p>
                    </div>
                    <div class="bd-highlight w-100 py-2">
                       <router-link to="/service/covid/qrcode">
                            <button class="btn btn-show-booking py-3">
                                <p class="text-white fm-poppins fst-normal fw-600 fs-14 lh-21 mb-0">Lihat Pesanan</p>
                            </button>
                       </router-link>
                    </div>
                    <div class="bd-highlight w-100 py-2">
                        <router-link to="/service/covid/payment">
                            <button class="btn btn-back py-3">
                                <p class="text-placeholder fm-poppins fst-normal fw-600 fs-14 lh-21 mb-0">Kembali</p>
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.title-success {
    font-weight: 600;
    font-size: 36px;
    line-height: 100%;
    color: #333333;
}

.desc-success {
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: #828282;
}

.btn-show-booking {
    width: 100%;
    background: #007A98;
    border-radius: 10px !important;
}

.btn-show-booking p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.btn-back {
    width: 100%;
    border: 2px solid #A0A3BD;
    box-sizing: border-box;
    border-radius: 10px !important;
}

.btn-back p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #A0A3BD;
}
</style>

<script>
import SecondNavbar from '@/components/SecondNavbar.vue'

export default {
  components: {
    SecondNavbar
  }
}
</script>
